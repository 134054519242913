<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import {swalHelper} from "@/helpers/swal-helper";
import {required} from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import {paginationHelper} from "@/helpers/pagination-helper";
import CustomCardSubtitle from "@/components/custom-card/custom-card-subtitle.vue";
import EcatCkeditor from "../../../components/ecat-ckeditor.vue";
import {toastHelper} from "../../../helpers/toast-helper";

export default {

  components: {
    EcatCkeditor,
    CustomCardSubtitle,
    Multiselect,
    Layout,
    PageHeader
  },

  data() {
    return {
      languageId: "",
      submitted: false,

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        isBusy: false,
        inputPage: "",

        items: []
      },

      categories: null,
      categoriesArray: null,

      instruction: {
        categoryId: "",
        title: "",
        content: ""
      },

      selectedCategory: {},
      visibleModal: false
    };
  },

  validations: {
    instruction: {
      orderValue: { required },
      categoryId: {required},
      title: {required},
      content: {required}
    }
  },

  methods: {

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('instructions.title'),
          active: true
        }
      ]
    },

    getFields() {
      return [
        {key: "category", slot: true, label: this.$t('instructions.table.category')},
        {key: "title", label: this.$t('instructions.table.title')},
        // {key: "content", slot: true, label: this.$t('instructions.table.content')},
        {
          key: "createdAt", label: this.$t('instructions.table.created-at'), formatter: value => {
            const dateOptions = {
              timeZone: 'Europe/Warsaw',
              weekday: 'long',
              month: 'long',
              day: 'numeric',
              year: 'numeric'
            };

            const dateFormatter = new Intl.DateTimeFormat(this.$store.getters["translation/getCurrentLocaleOrFallback"], dateOptions);
            return dateFormatter.format(new Date(value));
          }
        },
        {key: "action", slot: true, label: this.$t('table.actions')}
      ]
    },

    getCategoryName(categoryId) {
      const category = this.categories.get(categoryId);
      if (!category) {
        return "";
      }

      return category.title;
    },

    getCategory(categoryId) {
      const category = this.categories.get(categoryId);
      if (!category) {
        return {};
      }

      return category;
    },

    selectCategory(category) {
      this.selectedCategory = category
      this.instruction.categoryId = category.id
    },

    async loadInstructions() {
      try {
        let page = this.table.currentPage - 1;
        if (page > 0) {
          page = this.table.currentPage * this.table.perPage - this.table.perPage;
        }

        const {data} = await axios.get(`/instruction/pagination`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            page: page,
            size: this.table.perPage,
            languageId: this.languageId,
          }
        });

        this.table.items = data.instructions
        this.table.totalRows = data.count
        this.table.rows = data.count
      } catch (error) {
        errorCatcher.catchErrors(error)
      }

      return this.table.items
    },

    async loadCategories() {
      try {
        const {data} = await axios.get(`/instruction-category`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            languageId: this.languageId
          }
        });

        this.categoriesArray = data;
        this.categories = new Map(data.map((obj) => [obj.id, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    deleteInstruction(instruction) {
      swalHelper.yesOrNo(() => {
        const json = {
          id: instruction.id
        }

        axios.delete(`/instruction`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: json
        }).then(() => {
          toastHelper.success(this.$t('instructions.delete.success'))
          this.$refs.table.refresh()
        }).catch((error) => {
          errorCatcher.catchErrors(error)
        });
      });
    },

    // eslint-disable-next-line no-unused-vars
    hideModal(e) {
      this.submitted = false;
      this.visibleModal = false;
      this.instruction = {
        categoryId: "",
        title: "",
        content: ""
      }
      this.selectedCategory = {};
    },

    editInstruction(instruction) {
      this.instruction = Object.assign({}, instruction);
      this.selectedCategory = this.getCategory(instruction.categoryId);
      this.visibleModal = true
    },

    createOrEditInstruction() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.instruction.$touch();
      if (this.$v.instruction.$invalid) {
        return;
      }

      const json = {
        ...this.instruction,
        languageId: this.languageId
      }

      axios.put(`/instruction`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        toastHelper.success(this.$t(this.instruction.id ? "instructions.edit.success" : "instructions.create.success"))
        this.$refs.table.refresh()
        this.hideModal()
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    }

  },

  computed: {
    paginationHelper() {
      return paginationHelper
    }
  },

  async beforeMount() {
    this.languageId = this.$route.params.languageId
    await this.loadCategories()
  },

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('instructions.title')" :items="getItems()"/>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <custom-card-subtitle title="Akcje"/>

              <div class="col-xl-12">
                <a class="clickable-element btn btn-success mb-2" @click="visibleModal = true">
                  <i class="mdi mdi-plus mr-2"></i> {{ $t('instructions.create.button') }}
                </a>

                <router-link :to="`/dashboard/admin/instructions-categories/${languageId}`" class="clickable-element btn btn-primary mb-2 mx-2">
                  <i class="mdi mdi-view-list mr-2"></i> {{ $t('instructions.categories') }}
                </router-link>
              </div>
            </div>

            <ecat-table
                ref="table"
                :fields="getFields()"
                :items="loadInstructions"
                :pagination-top="true"
                :table="table">
<!--              <template v-slot:content="{ item }">-->
<!--                <div v-html="item.content"/>-->
<!--              </template>-->

              <template v-slot:category="{ item }">
                <span> {{ getCategoryName(item.categoryId) }}</span>
              </template>

              <template v-slot:action="{ item }">
                <div style="width: 120px;">
                  <a @click="editInstruction(item)" class="clickable-element mr-3 text-primary"><i class="mdi mdi-pencil font-size-18"></i></a>
                  <a @click="deleteInstruction(item)" class="clickable-element text-danger"><i class="mdi mdi-trash-can font-size-18"></i></a>
                </div>
              </template>
            </ecat-table>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <b-modal
        id="modal-1"
        size="xl"
        v-model="visibleModal"
        :title="$t(instruction.id ? 'instructions.edit.title' : 'instructions.create.title')"
        title-class="font-18"
        hide-footer
        @hide="hideModal"
        @esc="hideModal">
      <form>
        <div class="form-group">
          <label>Kolejność wyświetlania</label>
          <input v-model.number="instruction.orderValue" class="form-control"
                 :class="{ 'is-invalid': submitted && $v.instruction.orderValue.$error }"/>
          <div v-if="submitted && !$v.instruction.orderValue.required" class="invalid-feedback">
            {{ $t('message.required') }}
          </div>
        </div>

        <div v-if="categories" class="form-group">
          <label for="shop">{{ $t('instructions.table.category') }}</label>
          <multiselect :class="{ 'is-invalid': submitted && $v.instruction.categoryId.$error }"
                       :custom-label="value => getCategoryName(value.id)"
                       :options="categoriesArray"
                       placeholder="Wybierz kategorie"
                       :searchable="true"
                       :value="selectedCategory"
                       class="mb-4"
                       @select="value => selectCategory(value)">>
            <span slot="noOptions">{{ $t('message.list-is-empty') }}</span>
          </multiselect>
          <div v-if="submitted && $v.instruction.categoryId.$error" class="invalid-feedback">
            <span v-if="!$v.instruction.categoryId.required">{{ $t('message.required') }}</span>
          </div>
        </div>

        <div class="form-group">
          <label for="title">{{ $t('instructions.table.title') }}</label>
          <input id="title" v-model="instruction.title" type="text" name="title" class="form-control"
                 :class="{ 'is-invalid': submitted && $v.instruction.title.$error }"/>
          <div v-if="submitted && !$v.instruction.title.required" class="invalid-feedback">
            {{ $t('message.required') }}
          </div>
        </div>

        <div class="form-group ck-body-wrapper">
          <label for="content">{{ $t('instructions.table.content') }}</label>
          <ecat-ckeditor id="content" v-model="instruction.content"
                    :class="{ 'is-invalid': submitted && $v.instruction.content.$error }"></ecat-ckeditor>
          <div v-if="submitted && !$v.instruction.content.required" class="invalid-feedback">
            {{ $t('message.required') }}
          </div>
        </div>

        <div class="text-right">
          <b-button @click="createOrEditInstruction" variant="success">
            {{ $t(instruction.id ? 'instructions.edit.button' : 'instructions.create.button') }}
          </b-button>
          <b-button class="ml-1" variant="danger" @click="hideModal">{{ $t('message.cancel') }}</b-button>
        </div>
      </form>
    </b-modal>

  </Layout>
</template>